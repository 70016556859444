const KeyWordLocalization = {
    TRADE_MARK: 'TRADE_MARK',
    
    //#region---------------------- PAGES ----------------------//
    PropertiesPageResult: 'PropertiesPageResult',
    PropertiesPageLoading: 'PropertiesPageLoading',
    PropertiesPageSeeIn: 'PropertiesPageSeeIn',
    PropertiesPageGrid: 'PropertiesPageGrid',
    PropertiesPageList: 'PropertiesPageList',
    PropertiesPageOrderBy: 'PropertiesPageOrderBy',
    PropertiesPageFeatured: 'PropertiesPageFeatured',
    PropertiesPageMoreRecent: 'PropertiesPageMoreRecent',
    PropertiesPageMoreAncient: 'PropertiesPageMoreAncient',
    PropertiesPagePriceAsc: 'PropertiesPagePriceAsc',
    PropertiesPagePriceDesc: 'PropertiesPagePriceDesc',
    PropertiesPageMoreSeen: 'PropertiesPageMoreSeen',
    PropertiesPageMoreLiked: 'PropertiesPageMoreLiked',
    PropertiesPageTotalLoading: 'PropertiesPageTotalLoading',
    PropertiesPageClearAllFilters: 'PropertiesPageClearAllFilters',

    ContactUsPageTitle: 'ContactUsPageTitle',
    ContactUsPageTitle2: "ContactUsPageTitle2",
    ContactUsPageDescription: 'ContactUsPageDescription',
    ContactUsPageName: 'ContactUsPageName',
    ContactUsPageLastName: 'ContactUsPageLastName',
    ContactUsPageEmail: 'ContactUsPageEmail',
    ContactUsPagePhone: 'ContactUsPagePhone',
    ContactUsPageMessage: 'ContactUsPageMessage',
    ContactUsPageSuscribe: 'ContactUsPageSuscribe',
    ContactUsPageSavePreferences: 'ContactUsPageSavePreferences',
    ContactUsPageTermsAndConditions: 'ContactUsPageTermsAndConditions',
    ContactUsPageButton: 'ContactUsPageButton',
    ContactUsPageContactData: 'ContactUsPageContactData',
    ContactUsPageFaceToFaceCare: 'ContactUsPageFaceToFaceCare',
    ContactUsPageAddress: 'ContactUsPageAddress',
    ContactUsPageCareer: 'ContactUsPageCareer',
    ContactUsPageHouse: 'ContactUsPageHouse',
    ContactUsPageOpeningHours: 'ContactUsPageOpeningHours',
    ContactUsPageSchedule: "ContactUsPageSchedule",
    ContactUsPageMondayToFriday: 'ContactUsPageMondayToFriday',
    ContactUsPageSaturday: 'ContactUsPageSaturday',

    FavoritesPageTitle: "FavoritesPageTitle",
    FavoritesPageLAC: "FavoritesPageLAC",
    FavoritesPageNoFavoritesMiniTitle: "FavoritesPageNoFavoritesMiniTitle",
    FavoritesPageNoFavoritesTitle: "FavoritesPageNoFavoritesTitle",
    FavoritesPageHowAddFavorites: "FavoritesPageHowAddFavorites",
    FavoritesPageSeeProperties: "FavoritesPageSeeProperties",

    TermsAndConditionsPageTitle: "TermsAndConditionsPageTitle",

    PrivacyPolicyPageTitle: "PrivacyPolicyPageTitle",

    DetailedPropertyPageContactUs: "DetailedPropertyPageContactUs",

    RecommendedByUserPageTitle: "RecommendedByUserPageTitle",
    RecommendedByUserPageLAC: "RecommendedByUserPageLAC",
    RecommendedByUserPageShareUrl: "RecommendedByUserPageShareUrl", //url
    RecommendedByUserPageShare: "RecommendedByUserPageShare",
    //#endregion---------------------- PAGES ----------------------//

    //#region---------------------- COMPONENTS ----------------------//
    FooterLinkComponentTitle: "FooterLinkComponentTitle",
    FooterLinkComponentRow: "FooterLinkComponentRow", //type, business, city

    RangePickerComponentMinimum: 'RangePickerComponentMinimum',
    RangePickerComponentMaximum: 'RangePickerComponentMaximum',
    
    HeaderComponent_: 'HeaderComponent_',
    HeaderComponent_sell: 'HeaderComponent_sell',
    HeaderComponent_rent: 'HeaderComponent_rent',
    HeaderComponent_permutation: 'HeaderComponent_permutation',
    HeaderComponentMap: 'HeaderComponentMap',
    HeaderComponentProperties: "HeaderComponentProperties",
    HeaderComponentServices: "HeaderComponentServices",
    HeaderComponentServicesSell: "HeaderComponentServicesSell",
    HeaderComponentServicesBuy: "HeaderComponentServicesBuy",
    HeaderComponentServicesRent: "HeaderComponentServicesRent",
    HeaderComponentServicesAdministration: "HeaderComponentServicesAdministration",
    HeaderComponentServicesRealStateAdvice: "HeaderComponentServicesRealStateAdvice",
    HeaderComponentServicesInvestment: "HeaderComponentServicesInvestment",
    HeaderComponentAboutUs: "HeaderComponentAboutUs",
    HeaderComponentTools: "HeaderComponentTools",
    HeaderComponentToolsNotarialChargesCalculator: "HeaderComponentToolsNotarialChargesCalculator",
    HeaderComponentToolsGuides: "HeaderComponentToolsGuides",
    HeaderComponentToolsAddRentPayment: "HeaderComponentToolsAddRentPayment",
    HeaderComponentToolsCreditCalculator: "HeaderComponentToolsCreditCalculator",
    HeaderComponentContactUs: "HeaderComponentContactUs",
    HeaderComponentBlog: "HeaderComponentBlog",
    HeaderComponentFavorites: "HeaderComponentFavorites",

    FooterComponentContact: "FooterComponentContact",
    FooterComponentContactUs: "FooterComponentContactUs",
    FooterComponentCallMe: "FooterComponentCallMe",
    FooterComponentSchedule: "FooterComponentSchedule",
    FooterComponentMondayToFriday: "FooterComponentMondayToFriday",
    FooterComponentSaturday: "FooterComponentSaturday",
    FooterComponentFolowUs: "FooterComponentFolowUs",
    FooterComponentSocialMedia: "FooterComponentSocialMedia",
    FooterComponentCopyRight: "FooterComponentCopyRight",
    FooterComponentTermsAndConditions: "FooterComponentTermsAndConditions",
    FooterComponentPrivacyPolicy: "FooterComponentPrivacyPolicy",

    PropertydFavoriteComponentWithoutFavorites: "PropertydFavoriteComponentWithoutFavorites", //icon

    SideFilterWordSearcher: "SideFilterWordSearcher",
    SideFilterWordSearch: "SideFilterWordSearch",
    SideFilterWordSort: "SideFilterWordSort",
    SideFilterWordSortNewest: "SideFilterWordSortNewest",
    SideFilterWordSortOldest: "SideFilterWordSortOldest",
    SideFilterWordSortPriceAsc: "SideFilterWordSortPriceAsc",
    SideFilterWordSortPriceDesc: "SideFilterWordSortPriceDesc",
    SideFilterWordSortMoreLiked: "SideFilterWordSortMoreLiked",
    SideFilterWordSortMoreSeen: "SideFilterWordSortMoreSeen",
    SideFilterCity: "SideFilterCity",
    SideFilterCharacteristics: "SideFilterCharacteristics",
    SideFilterPrice: "SideFilterPrice",
    SideFilterRooms: "SideFilterRooms",
    SideFilterBathrooms: "SideFilterBathrooms",
    SideFilterArea: "SideFilterArea",
    SideFilterGarage: "SideFilterGarage",
    SideFilterStratum: "SideFilterStratum",
    SideFilterState: "SideFilterState",
    SideFilterStateNew: "SideFilterStateNew",
    SideFilterStateUsed: "SideFilterStateUsed",
    SideFilterSearch: "SideFilterSearch",

    PaginatorComponentShowing: "PaginatorComponentShowing", //showing, total

    TopSearcherBusinessType: "TopSearcherBusinessType",
    TopSearcherPropertyType: "TopSearcherPropertyType",

    CardPropertyComponentArea: "CardPropertyComponentArea",
    CardPropertyComponentRooms: "CardPropertyComponentRooms",
    CardPropertyComponentRestrooms: "CardPropertyComponentRestrooms",
    CardPropertyComponentFloors: "CardPropertyComponentFloors",
    CardPropertyComponentJoinTitle: "CardPropertyComponentJoinTitle", 

    SideComponentTags: "SideComponentTags",
    SideComponentLocation: "SideComponentLocation",
    SideComponentNeighborhood: "SideComponentNeighborhood",
    SideComponentZones: "SideComponentZones",

    ContactPropertyComponentContactMe: 'ContactPropertyComponentContactMe',
    ContactPropertyComponentWhatsAppLink: 'ContactPropertyComponentWhatsAppLink', //link
    ContactPropertyComponentMailSubject: 'ContactPropertyComponentMailSubject',
    ContactPropertyComponentMailBody: 'ContactPropertyComponentMailBody', //link

    ContactFormPropertyComponentName: "ContactFormPropertyComponentName",
    ContactFormPropertyComponentLastname: "ContactFormPropertyComponentLastname",
    ContactFormPropertyComponentEmail: "ContactFormPropertyComponentEmail",
    ContactFormPropertyComponentPhone: "ContactFormPropertyComponentPhone",
    ContactFormPropertyComponentDial: "ContactFormPropertyComponentDial",
    ContactFormPropertyComponentMessage: "ContactFormPropertyComponentMessage",
    ContactFormPropertyComponentSubscribe: "ContactFormPropertyComponentSubscribe",
    ContactFormPropertyComponentSavePreference: "ContactFormPropertyComponentSavePreference",
    ContactFormPropertyComponentAccept: "ContactFormPropertyComponentAccept",
    ContactFormPropertyComponentSubmit: "ContactFormPropertyComponentSubmit",
    ContactFormPropertyComponentPhoneNoIndex: "ContactFormPropertyComponentPhoneNoIndex",

    NeedLoanComponentTitle: "NeedLoanComponentTitle",
    NeedLoanComponentWithoutAsociatedText: "NeedLoanComponentWithoutAsociatedText",
    NeedLoanComponentMortgage: "NeedLoanComponentMortgage",
    NeedLoanComponentLeasing: "NeedLoanComponentLeasing",
    NeedLoanComponentFinance: "NeedLoanComponentFinance",
    NeedLoancomponentMonthlyAproximately: "NeedLoancomponentMonthlyAproximately",
    NeedLoanComponentWantFinance: "NeedLoanComponentWantFinance",
    NeedLoanComponentClarifications: "NeedLoanComponentClarifications",
    NeedLoanComponentWhatsApp: "NeedLoanComponentWhatsApp",

    HelpSearchPropertyComponentTitle: "HelpSearchPropertyComponentTitle",
    HelpSearchPropertyComponentBody: "HelpSearchPropertyComponentBody",  
    HelpSearchPropertyComponentButton: "HelpSearchPropertyComponentButton",
    HelperSearchPropertyComponentWhatsapp: "HelperSearchPropertyComponentWhatsapp",
    HelpSearchPropertyComponentHelpImageTitle: "HelpSearchPropertyComponentHelpImageTitle",

    ShareLinkModalComponentWhatsapp: "ShareLinkModalComponentWhatsapp",
    ShareLinkModalComponentFacebook: "ShareLinkModalComponentFacebook",
    ShareLinkModalComponentX: "ShareLinkModalComponentX",
    ShareLinkModalComponentTelegram: "ShareLinkModalComponentTelegram",
    ShareLinkModalComponentCopyClipboard: "ShareLinkModalComponentCopyClipboard",
    ShareLinkModalComponentLine: "ShareLinkModalComponentLine",
    ShareLinkModalComponentLinkedIn: "ShareLinkModalComponentLinkedIn",
    ShareLinkModalComponentEmail: "ShareLinkModalComponentEmail",
    ShareLinkModalComponentFullLink: "ShareLinkModalComponentFullLink",

    ModalCustomCompoonentCloseButton: "ModalCustomCompoonentCloseButton",

    LoadingComponentLoading: "LoadingComponentLoading",

    FinishModalErrorBody: "FinishModalErrorBody",
    FinishModalErrorTitle: "FinishModalErrorTitle",
    FinishModalSuccessBody: "FinishModalSuccessBody",
    FinishModalSuccessTitle: "FinishModalSuccessTitle",
    FinishModalSuccessButtonText: "FinishModalSuccessButtonText",

    CarouselPropertyComponentShare: "CarouselPropertyComponentShare",
    CarouselPropertyComponentLike: "CarouselPropertyComponentLike",
    CarouselPropertyComponentShareLocation: "CarouselPropertyComponentShareLocation", //city, neighborhood, zone
    CarouselPropertyComponentSharePrice: "CarouselPropertyComponentSharePrice", //business, price
    CarouselPropertyComponentShareArea: "CarouselPropertyComponentShareArea", //area
    CarouselPropertyComponentShareRooms: "CarouselPropertyComponentShareRooms", //rooms
    CarouselPropertyComponentShareRestrooms: "CarouselPropertyComponentShareRestrooms", //restrooms
    CarouselPropertyComponentShareGarages: "CarouselPropertyComponentShareGarages", //garages
    CarouselPropertyComponentShareContainer: "CarouselPropertyComponentShareContainer", //content, link, typeProperty
    CarouselPropertyComponentShareProperty: "CarouselPropertyComponentShareProperty",

    DetailsPropertyComponentMainCharacteristics: "DetailsPropertyComponentMainCharacteristics",
    DetailsPropertyComponentPropertyCode: "DetailsPropertyComponentPropertyCode",
    DetailsPropertyComponentPropertyNeighborhood: "DetailsPropertyComponentPropertyNeighborhood",
    DetailsPropertyComponentPropertyLocalization: "DetailsPropertyComponentPropertyLocalization",
    DetailsPropertyComponentPropertyArea: "DetailsPropertyComponentPropertyArea",
    DetailsPropertyComponentPropertyState: "DetailsPropertyComponentPropertyState",
    DetailsPropertyComponentPropertyAdministration: "DetailsPropertyComponentPropertyAdministration",
    DetailsPropertyComponentPropertyRooms: "DetailsPropertyComponentPropertyRooms",
    DetailsPropertyComponentPropertyBathrooms: "DetailsPropertyComponentPropertyBathrooms",
    DetailsPropertyComponentPropertyParking: "DetailsPropertyComponentPropertyParking",
    DetailsPropertyComponentPropertyAditionalCharacteristics: "DetailsPropertyComponentPropertyAditionalCharacteristics",
    DetailsPropertyComponentPropertyBuildYear: "DetailsPropertyComponentPropertyBuildYear",
    DetailsPropertyComponentPropertyStratum: "DetailsPropertyComponentPropertyStratum",
    DetailsPropertyComponentPropertyFloor: "DetailsPropertyComponentPropertyFloor",

    RelatedPropertiesComponentTitle: "RelatedPropertiesComponentTitle",

    PropertyMarkerLocation: "PropertyMarkerLocation",

    HeaderPropertyComponentVisits: "HeaderPropertyComponentVisits",
    HeaderPropertyComponentJoinTitleWord: "HeaderPropertyComponentJoinTitleWord",
    HeaderPropertyComponentJoinCityWord: "HeaderPropertyComponentJoinCityWord",

    MapsClosePropertiesComponentHide: "MapsClosePropertiesComponentHide",
    MapsClosePropertiesComponentShow: "MapsClosePropertiesComponentShow",

    NotFoundComponentMiniTitle: 'NotFoundComponentMiniTitle',
    NotFoundComponentTitle: 'NotFoundComponentTitle',
    NotFoundComponentDescription: 'NotFoundComponentDescription',
    NotFoundComponentGoHome: 'NotFoundComponentGoHome',
    //#endregion---------------------- COMPONENTS ----------------------//

    //#region---------------------- OTHERS ----------------------//
    SEOProperty: "SEOProperty",
    SEOImagesPropertyInBusiness: "SEOImagesPropertyInBusiness", //property, business
    SEOImagePropertyrestrooms: "SEOImagePropertyrestrooms", //restrooms
    SEOImagePropertyrestroomsPlural: "SEOImagePropertyrestroomsPlural", //restrooms
    SEOImagePropertyRooms: "SEOImagePropertyRooms", //rooms
    SEOImagePropertyRoomsPlural: "SEOImagePropertyRoomsPlural", //rooms
    SEOImagePropertyGarages: "SEOImagePropertyGarages", //garages
    SEOImagePropertyGaragesPlural: "SEOImagePropertyGaragesPlural", //garages
    SEOImagePropertyArea: "SEOImagePropertyArea", //area
    SEOImagePropertyCity: "SEOImagePropertyCity", //city
    SEOImagePropertyStratum: "SEOImagePropertyStratum", //stratum
    SEOPrice: "SEOPrice", //business
    SEOPropertiesAvailables: "SEOPropertiesAvailables",
    SEOPropertiesPropertyAvailables: "SEOPropertiesPropertyAvailables", //property
    SEOImagesPropertyInBusinessWithCity: "SEOImagesPropertyInBusinessWithCity", //property, business, city
    SEOImagesPropertyInBusinessWithoutBusinessWithCity: "SEOImagesPropertyInBusinessWithoutBusinessWithCity", //property, city

    GeneralDescriptionMetaTag: "GeneralDescriptionMetaTag",
    GeneralKeywordsMetaTag: "GeneralKeywordsMetaTag",
    GeneralTitleMetaTag: "GeneralTitleMetaTag",

    PropertiesMetaTagComponentTitle: "PropertiesMetaTagComponentTitle", //typeProperty, city
    PropertiesMetaTagComponentInvestmentProperty: "PropertiesMetaTagComponentInvestmentProperty",
    PropertiesMetaTagComponentInvestmentTitle: "PropertiesMetaTagComponentInvestmentTitle", //typeProperty, city, //typeBusiness
    PropertiesMetaTagComponentDescription: "PropertiesMetaTagComponentDescription", //
    PropertiesMetaTagComponentInvestmentDescription: "PropertiesMetaTagComponentInvestmentDescription", //city
    PropertiesMetaTagComponentKeywords: "PropertiesMetaTagComponentKeywords", //typeProperty, typeBusiness, city, tags

    DetailedPropertyMetaTagComponentNotFoundTitle: "DetailedPropertyMetaTagComponentNotFoundTitle",
    DetailedPropertyMetaTagComponentNotFoundSubtitle: "DetailedPropertyMetaTagComponentNotFoundTitle", //ID
    DetailedPropertyMetaTagComponentTitle: "DetailedPropertyMetaTagComponentTitle", //typeProperty, business, neighborhood, city
    DetailedPropertyMetaTagComponentSubtitle: "DetailedPropertyMetaTagComponentSubtitle", //typeProperty, business, zone, neighborhood, city, area, rooms, restrooms, stratum, prices, garages
    DetailedPropertyMetaTagComponentKeyWords: "DetailedPropertyMetaTagComponentKeyWords", //typeProperty. business, neighborhood, city, zone, area, garage, rooms, stratum

    FavoritesPageMetaTagComponentTitle: "FavoritesPageMetaTagComponentTitle",
    FavoritesPageMetaTagComponentDescription: "FavoritesPageMetaTagComponentDescription",
    FavoritesPageMetaTagComponentKeyWords: "FavoritesPageMetaTagComponentKeyWords",

    ContactUsPageMetaTagComponentTitle: "ContactUsPageMetaTagComponentTitle",
    ContactUsPageMetaTagComponentDescription: "ContactUsPageMetaTagComponentDescription",
    ContactUsPageMetaTagComponentKeyWords: "ContactUsPageMetaTagComponentKeyWords",

    TermsAndConditionsPageMetaTagComponentTitle: "TermsAndConditionsPageMetaTagComponentTitle",
    TermsAndConditionsPageMetaTagComponentDescription: "TermsAndConditionsPageMetaTagComponentDescription",
    TermsAndConditionsPageMetaTagComponentKeyWords: "TermsAndConditionsPageMetaTagComponentKeyWords",

    PrivacyPolicyPageMetaTagComponentTitle: "PrivacyPolicyPageMetaTagComponentTitle",
    PrivacyPolicyPageMetaTagComponentDescription: "PrivacyPolicyPageMetaTagComponentDescription",
    PrivacyPolicyPageMetaTagComponentKeyWords: "PrivacyPolicyPageMetaTagComponentKeyWords",

    RecommendedByUserPageMetaTagComponentTitle: "RecommendedByUserPageMetaTagComponentTitle",
    RecommendedByUserPageMetaTagComponentDescription: "RecommendedByUserPageMetaTagComponentDescription",
    RecommendedByUserPageMetaTagComponentKeyWords: "RecommendedByUserPageMetaTagComponentKeyWords",

    OtherIn: "OtherIn",
    OtherColombia: "OtherColombia",
    OtherCaliOrJamundi: "OtherCaliOrJamundi",

    AdrianaShortName: "AdrianaShortName",
    AdrianaPhone: "AdrianaPhone",
    AdrianaPhone2: "AdrianaPhone2",
    AdrianaEmail: "AdrianaEmail",
    MarlonShortName: "MarlonShortName",
    MarlonPhone: "MarlonPhone",
    MarlonEmail: "MarlonEmail",
    //#endregion---------------------- SEO ----------------------//

    //#region---------------------- VALIDATORS ----------------------//
    ValidatorRequired: 'ValidatorRequired',
    ValidatorEmail: 'ValidatorEmail',
    ValidatorMinLength: 'ValidatorMinLength',
    ValidatorMaxLength: 'ValidatorMaxLength',
    ValidatorMinValue: 'ValidatorMinValue',
    ValidatorMaxValue: 'ValidatorMaxValue',
    ValidatorMustBeNumber: 'ValidatorMustBeNumber',
    ValidatorSpecialCharactersIsRequired: 'ValidatorSpecialCharactersIsRequired',
    ValidatorUpperCaseIsRequired: 'ValidatorUpperCaseIsRequired',
    ValidatorLowerCaseIsRequired: 'ValidatorLowerCaseIsRequired',
    ValidatorNumberIsRequired: 'ValidatorNumberIsRequired',
    ValidatorMustBeEqual: 'ValidatorMustBeEqual',
    ValidatorSpecialCharacterIsNotAllowed: 'ValidatorSpecialCharacterIsNotAllowed',
    ValidatorUpperCaseIsNotAllowed: 'ValidatorUpperCaseIsNotAllowed',
    ValidatorLowerCaseIsNotAllowed: 'ValidatorLowerCaseIsNotAllowed',
    ValidatorNumberIsNotAllowed: 'ValidatorNumberIsNotAllowed',
    ValidatorIsNotName: 'ValidatorIsNotName',
    ValidatorIsNotPhone: 'ValidatorIsNotPhone',
    ValidatorGreaterThan: 'ValidatorGreaterThan', //value
    ValidatorLessThan: 'ValidatorLessThan', //value
    ValidatorMustHavePartBeforeAt: 'ValidatorMustHavePartBeforeAt',
    //#endregion---------------------- VALIDATORS ----------------------//

    //#region---------------------- SERVER TABLES ----------------------//
    //type business
    sell: 'sell',
    rent: 'rent',
    permutation: 'permutation',
    
    //link type business
    sellLink: 'sellLink',
    rentLink: 'rentLink',
    permutationLink: 'permutationLink',

    //type properties
    apartment: 'apartment',
    studioApartment: 'studioApartment',
    warehouse: 'warehouse',
    house: 'house',
    countryHouse: 'countryHouse',
    estate: 'estate',
    local: 'local',
    lot: 'lot',
    office: 'office',
    farm: "farm",
    apartmentPlural: 'apartmentPlural',
    studioApartmentPlural: 'studioApartmentPlural',
    warehousePlural: 'warehousePlural',
    housePlural: 'housePlural',
    countryHousePlural: 'countryHousePlural',
    estatePlural: 'estatePlural',
    localPlural: 'localPlural',
    lotPlural: 'lotPlural',
    officePlural: 'officePlural',
    farmPlural: "farmPlural",

    //tag filters
    outstanding: "outstanding",
    new: "new",
    unique_oportunity: "unique_oportunity",
    investment_oportunity: "investment_oportunity",
    coming_soon: "coming_soon",

    //zones
    norte: 'norte',
    sur: 'sur',
    este: 'este',
    oeste: 'oeste',
    occidente: "occidente",
    oriente: "oriente",
    centro: 'centro',

    //#endregion---------------------- SERVER TABLES ----------------------//


    //interest points
    attached: 'attached',
    integralKitchen: 'integralKitchen',
    ceramicFloor: 'ceramicFloor',
    lavanderyZone: 'lavanderyZone',
    auxiliarRestroom: 'auxiliarRestroom',
    singleFamily: 'singleFamily',
    closet: 'closet',
    panoramicView: 'panoramicView',
    pavedAccess: 'pavedAccess',
    schoolsSoon: 'schoolsSoon',
    universitySoon: 'universitySoon',
    publicTransport: 'publicTransport',
    supermarket: 'supermarket',
    park: 'park',
    urbanSoon: 'urbanSoon',
    onMainRoad: 'onMainRoad',

    MasterItemEntityKitchenIntegralKitchen: "MasterItemEntityKitchenIntegralKitchen",

    //banner
    what_can_we_help_you: 'what_can_we_help_you',
    what_can_we_help_you_subtitle: 'what_can_we_help_you_subtitle',
    properties_banner: 'properties_banner',
    properties_banner_subtitle: 'properties_banner_subtitle',
    about_us_banner: 'about_us_banner',
    about_us_banner_subtitle: 'about_us_banner_subtitle',
    contact_banner_subtitle: 'contact_banner_subtitle',
    i_need_real_state_advice: 'i_need_real_state_advice',
    i_want_to_sell_my_property: 'i_want_to_sell_my_property',
    see_more: 'see_more',
    see_less: 'see_less',

    //property
    cop: 'cop',
    and: 'and',
    in_neighborhood: 'in_neighborhood',
    of: 'of',
    basic_features: 'basic_features',
    aditional_features: 'aditional_features',
    description: 'description',
    likes_apply_to: 'likes_apply_to',
    apply: 'apply',


    //outstanding
    last_properties: 'last_properties',
    outstanding_properties: 'outstanding_properties',

    //features
    area: 'area',
    restrooms: 'restrooms',
    rooms: 'rooms',
    private_area: 'private_area',
    builded_area: 'builded_area',
    type_kitchen: 'type_kitchen',
    build_year: 'build_year',
    stratum: 'stratum',
    floors: 'floors',
    parkings: 'parkings',
    state_property: 'state_property',
    price_admin: 'price_admin',
    new_property: 'new_property',
    used_property: 'used_property',
    share_property: 'share_property',
    select_where_you_want_to_share_this_property: 'select_where_you_want_to_share_this_property',
    download_pdf: 'download_pdf',
    zones: 'zones',
    neighborhoods: 'neighborhoods',
    status: 'status',

    //contact
    contact_form_name: 'contact_form_name',
    contact_form_lastname: 'contact_form_lastname',
    contact_form_document_number: 'contact_form_document_number',
    contact_form_document: 'contact_form_document',
    contact_form_email: 'contact_form_email',
    contact_form_phone: 'contact_form_phone',
    contact_form_comment: 'contact_form_comment',
    contact_form_receive_notifications: 'contact_form_receive_notifications',
    contact_form_contact_me: 'contact_form_contact_me',
    contact_form_accept: 'contact_form_accept',
    contact_form_dial: 'contact_form_dial',
    contact_form_social_message: 'contact_form_social_message',

    //services
    services_title: 'services_title',
    //Error page
    ups: 'oops!_this_page_is_not_available',
    this_happens_because_the_page_was_moved: 'this_happens_because_the_page_was_moved',
    home_page: 'home_page',
    go_back_to_home: 'go_back_to_home',

    //Privacy policy
    privacy_policy_title: 'privacy_policy_title',
    privacy_policy_html: 'privacy_policy_html',

    //terms and conditions
    terms_and_conditions_title: 'terms_and_conditions_title',
    terms_and_conditions_html: 'terms_and_conditions_html',

    //utils
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    
    ErrorSavingPreferences: "ErrorSavingPreferences",
    UnknownError: "UnknownError",

    day: 'day',
    month: 'month',
}

export default KeyWordLocalization;