import "./RecommendedByUserPageStyles.scss";
import { FC, useContext, useEffect, useState } from "react";
import di from "../../../../dependencyInjection/DependencyInjection";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import CardPropertyComponent from "../../../components/cardProperty/CardPropertyComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import KeyWordLocalization from "../../../providers/localization/KeyWordLocalization";
import RecommendedByUserPageMetaTagComponent from "../../../seo/metas/RecommendedByUserPageMetaTagComponent";
import PropertyEntity from "../../../../domain/entities/PropertyEntity";
import GetRecommendedByUserPropertiesUseCase, { GetRecommendedByUserPropertiesUseCaseName } from "../../../../domain/use_cases/properties/GetRecommendedByUserPropertiesUseCase";
import { useNavigate, useParams } from "react-router-dom";
import { isLeft } from "fp-ts/lib/Either";
import routes from "../../../routes/Routes";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import ShareLinkModalComponent from "../../../components/modals/shareLink/ShareLinkModalComponent";
import IconsSVG from "../../../components/icons/IconsSVG";

const RecommendedByUserPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType
    const [loading, setLoading] = useState<boolean>(true);
    const [recommendedProperties, setRecommendedProperties] = useState<PropertyEntity[]>([]);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const _handleShare = () => {
        const url = i18n(KeyWordLocalization.RecommendedByUserPageShareUrl, { url: window.location.href });
        openModalCustom(undefined, 'Share', <ShareLinkModalComponent link={url} />);
    }

    const _load = async () => {
        setLoading(true);
        setRecommendedProperties([]);
        const response = await di.get<GetRecommendedByUserPropertiesUseCase>(GetRecommendedByUserPropertiesUseCaseName).call(id!);
        if (response.length == 0) return navigate(routes().error404.relativePath);
        setRecommendedProperties(response);
        setLoading(false);
    }

    useEffect(() => {
        _load();
    }, []);

    if (loading) return <>
        <RecommendedByUserPageMetaTagComponent />
        <div className="container">
            <div className="p-5">
                <LoadingComponent showLogo />
            </div>
        </div>
    </>
    return <>
        <RecommendedByUserPageMetaTagComponent />
        <div className="recommended_by_user_page yes_added">
            <div className="w-100 bg_light">
                <div className="container py-3 d-flex">
                    <div className="flex-grow-1">
                        <h1 className="h5">{i18n(KeyWordLocalization.RecommendedByUserPageTitle)}</h1>
                        <h2 className="h6">{i18n(KeyWordLocalization.RecommendedByUserPageLAC)}</h2>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="mbtn btn_orange_outline d-flex align-items-center p-3 py-2" onClick={_handleShare}>
                            <span className="material-symbols-outlined me-2" style={{fontSize: 20}}>
                                share
                            </span>

                            {i18n(KeyWordLocalization.RecommendedByUserPageShare)}</button>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {recommendedProperties.map((property, index) => <div className="col-md-6 col-lg-4 col-xl-3 my-3" key={index}>
                        <CardPropertyComponent property={property} />
                    </div>)}
                </div>
            </div>
        </div>
    </>
}

export default RecommendedByUserPage;