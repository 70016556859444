import di from "../../dependencyInjection/DependencyInjection";
import UserEntity from "../../domain/entities/UserEntity";
import LocalizationProvider, { LocalizationProviderName } from "../../domain/providers/localization/LocalizationProvider";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "../../domain/use_cases/localization/GetCurrentLanguageUseCase";
import AboutUsPage from "../pages/aboutUs/AboutUsPage";
import BlogPage from "../pages/blog/BlogPage";
import BuyServicePage from "../pages/blog/BlogPage";
import ContactUsPage from "../pages/contact/ContactUsPage";
import Error404Page from "../pages/error404/Error404Page";
import FavoritesPage from "../pages/favorites/FavoritesPage";
import PrivacyPolicyPage from "../pages/legal/policies/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/legal/terminal/TermsAndConditionsPage";
import DetailedPropertyPage from "../pages/properties/detailed/DetailedPropertyPage";
import PropertiesPage from "../pages/properties/PropertiesPage";
import RecommendedByUserPage from "../pages/properties/recommendedByUser/RecommendedByUserPage";
import InvestmentServicePage from "../pages/services/investmentService/InvestmentServicePage";
import ManagmentServicePage from "../pages/services/managmentService/ManagmentServicePage";
import RealStateAdviceServicePage from "../pages/services/realStateAdviceService/RealStateAdviceServicePage";
import RentServicePage from "../pages/services/rentService/RentServicePage";
import SellServicePage from "../pages/services/sellService/SellServicePage";
import ServicePage from "../pages/services/ServicePage";
import AddRentPaymentAsClientPage from "../pages/tools/client/add_rent_payment/AddRentPaymentAsClient";
import CreditCalculatorPage from "../pages/tools/creditCalculator/CreditCalculatorPage";
import GuidesToolPage from "../pages/tools/guides/GuidesToolPage";
import NotarialCalculatorPage from "../pages/tools/notarialCalculator/NotarialCalculatorPage";

export const routesES = {
    home: {
        path: "/",
        relativePath: "/",
        component: PropertiesPage,
        auth: (user: UserEntity | undefined) => true,
    },
    contact: {
        path: "/contactanos",
        relativePath: "/contactanos",
        component: ContactUsPage,
        auth: (user: UserEntity | undefined) => true,
    },
    properties: {
        path: "/inmuebles/:businesses?/:types?/:city?",
        relativePath: "/inmuebles",
        component: PropertiesPage,
        pathTo: (businesses?: string, types?: string, city?: string): string => {
            if (city && businesses && types) return `/inmuebles/${businesses}/${types}/${city}`;
            if (city && !businesses && !types) return routes().properties_only_city.pathTo(city);
            else if (types) return `/inmuebles/${businesses && businesses != "" ? businesses : 'disponibles'}/${types}`;
            else if (businesses) return `/inmuebles/${businesses}`;
            else return `/inmuebles`;
        },
        auth: (user: UserEntity | undefined) => true,
    },
    properties_only_city: {
        path: "/inmuebles/ciudad/:city",
        relativePath: "/inmuebles/ciudad",
        component: PropertiesPage,
        pathTo: (city: string): string => `/inmuebles/ciudad/${city}`,
        auth: (user: UserEntity | undefined) => true,
    },
    property: {
        path: "/inmueble/:businesses/:description/:id",
        relativePath: "/inmueble",
        component: DetailedPropertyPage,
        auth: (user: UserEntity | undefined) => true,
    },
    // aboutUs: {
    //     path: "/sobre-nosotros",
    //     relativePath: "/sobre-nosotros",
    //     component: AboutUsPage,
    //     auth: (user: UserEntity | undefined) => true,
    // // },
    // services: {
    //     path: "/servicios",
    //     relativePath: "/servicios",
    //     component: ServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_sell: {
    //     path: "/servicios/vende-tu-propiedad",
    //     relativePath: "/servicios/vende-tu-propiedad",
    //     component: SellServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_buy: {
    //     path: "/servicios/comprar-propiedad-raiz",
    //     relativePath: "/servicios/comprar-propiedad-raiz",
    //     component: BuyServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_rent: {
    //     path: "/servicios/alquiler-de-propiedad-raiz",
    //     relativePath: "/servicios/alquiler-de-propiedad-raiz",
    //     component: RentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_real_state_advice: {
    //     path: "/servicios/asesoria-inmobiliaria",
    //     relativePath: "/servicios/asesoria-inmobiliaria",
    //     component: RealStateAdviceServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_management: {
    //     path: "/servicios/administracion-de-propiedad-raiz",
    //     relativePath: "/servicios/administracion-de-propiedad-raiz",
    //     component: ManagmentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_investment: {
    //     path: "/invertir-en-propiedad-raiz-en-colombia",
    //     relativePath: "/invertir-en-propiedad-raiz-en-colombia",
    //     component: InvestmentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // properties_map: {
    //     path: "/buscar-inmuebles-en-mi-zona/:typeBusiness?/:typeProperty?",
    //     relativePath: "/buscar-inmuebles-en-mi-zona",
    //     component: PropertiesMapPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // blog: {
    //     path: "/blog",
    //     relativePath: "/blog",
    //     component: BlogPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools: {
    //     path: "/herramientas",
    //     relativePath: "/herramientas",
    //     component: Error404Page,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_notarial_charges_calculator: {
    //     path: "/herramientas/calculadora-de-gastos-notariales",
    //     relativePath: "/herramientas/calculadora-de-gastos-notariales",
    //     component: NotarialCalculatorPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_guides: {
    //     path: "/herramientas/guias",
    //     relativePath: "/herramientas/guias",
    //     component: GuidesToolPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_credit_calculator: {
    //     path: "/herramientas/simulador-de-credito",
    //     relativePath: "/herramientas/simulador-de-credito",
    //     component: CreditCalculatorPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_add_my_payment: {
    //     path: "/cliente/agregar-mi-pago",
    //     relativePath: "/cliente/agregar-mi-pago",
    //     component: AddRentPaymentAsClientPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    favorites: {
        path: "/favoritos",
        relativePath: "/favoritos",
        component: FavoritesPage,
        auth: (user: UserEntity | undefined) => true,
    },
    terms_and_conditions: {
        path: "/legal/terminos-y-condiciones",
        relativePath: "/legal/terminos-y-condiciones",
        component: TermsAndConditionsPage,
        auth: (user: UserEntity | undefined) => true,
    },
    privacy_policy: {
        path: "/legal/politica-de-privacidad",
        relativePath: "/legal/politica-de-privacidad",
        component: PrivacyPolicyPage,
        auth: (user: UserEntity | undefined) => true,
    },
    our_recommendations_for_you: {
        path: "/nuestras-recomendaciones-para-ti/:id",
        relativePath: "/nuestras-recomendaciones-para-ti",
        component: RecommendedByUserPage,
        auth: (user: UserEntity | undefined) => true,
    },
    error404: {
        path: "/pagina-no-encontrada",
        relativePath: "/pagina-no-encontrada",
        component: Error404Page,
        auth: (user: UserEntity | undefined) => true,
    },
}

export const routesEN = {
    home: {
        path: "/en",
        relativePath: "/en",
        component: PropertiesPage,
        auth: (user: UserEntity | undefined) => true,
    },
    contact: {
        path: "/contact",
        relativePath: "/contact",
        component: ContactUsPage,
        auth: (user: UserEntity | undefined) => true,
    },
    properties: {
        path: "/properties/:businesses?/:types?/:city?",
        relativePath: "/properties",
        component: PropertiesPage,
        pathTo: (businesses?: string, types?: string, city?: string): string => {
            if (city && businesses && types) return `/properties/${businesses}/${types}/${city}`;
            if (city && !businesses && !types) return routesEN.properties_only_city.pathTo(city);
            else if (types) return `/properties/${businesses && businesses != "" ? businesses : 'availables'}/${types}`;
            else if (businesses) return `/properties/${businesses}`;
            else return `/properties`;
        },
        auth: (user: UserEntity | undefined) => true,
    },
    properties_only_city: {
        path: "/properties/city/:city",
        relativePath: "/properties/city",
        component: PropertiesPage,
        pathTo: (city: string): string => `/properties/city/${city}`,
        auth: (user: UserEntity | undefined) => true,
    },
    property: {
        path: "/property/:businesses/:description/:id",
        relativePath: "/property",
        component: DetailedPropertyPage,
        auth: (user: UserEntity | undefined) => true,
    },
    // aboutUs: {
    //     path: "/sobre-nosotros",
    //     relativePath: "/sobre-nosotros",
    //     component: AboutUsPage,
    //     auth: (user: UserEntity | undefined) => true,
    // // },
    // services: {
    //     path: "/servicios",
    //     relativePath: "/servicios",
    //     component: ServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_sell: {
    //     path: "/servicios/vende-tu-propiedad",
    //     relativePath: "/servicios/vende-tu-propiedad",
    //     component: SellServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_buy: {
    //     path: "/servicios/comprar-propiedad-raiz",
    //     relativePath: "/servicios/comprar-propiedad-raiz",
    //     component: BuyServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_rent: {
    //     path: "/servicios/alquiler-de-propiedad-raiz",
    //     relativePath: "/servicios/alquiler-de-propiedad-raiz",
    //     component: RentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_real_state_advice: {
    //     path: "/servicios/asesoria-inmobiliaria",
    //     relativePath: "/servicios/asesoria-inmobiliaria",
    //     component: RealStateAdviceServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_management: {
    //     path: "/servicios/administracion-de-propiedad-raiz",
    //     relativePath: "/servicios/administracion-de-propiedad-raiz",
    //     component: ManagmentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // services_investment: {
    //     path: "/invertir-en-propiedad-raiz-en-colombia",
    //     relativePath: "/invertir-en-propiedad-raiz-en-colombia",
    //     component: InvestmentServicePage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // properties_map: {
    //     path: "/buscar-inmuebles-en-mi-zona/:typeBusiness?/:typeProperty?",
    //     relativePath: "/buscar-inmuebles-en-mi-zona",
    //     component: PropertiesMapPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // blog: {
    //     path: "/blog",
    //     relativePath: "/blog",
    //     component: BlogPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools: {
    //     path: "/herramientas",
    //     relativePath: "/herramientas",
    //     component: Error404Page,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_notarial_charges_calculator: {
    //     path: "/herramientas/calculadora-de-gastos-notariales",
    //     relativePath: "/herramientas/calculadora-de-gastos-notariales",
    //     component: NotarialCalculatorPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_guides: {
    //     path: "/herramientas/guias",
    //     relativePath: "/herramientas/guias",
    //     component: GuidesToolPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_credit_calculator: {
    //     path: "/herramientas/simulador-de-credito",
    //     relativePath: "/herramientas/simulador-de-credito",
    //     component: CreditCalculatorPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    // tools_add_my_payment: {
    //     path: "/cliente/agregar-mi-pago",
    //     relativePath: "/cliente/agregar-mi-pago",
    //     component: AddRentPaymentAsClientPage,
    //     auth: (user: UserEntity | undefined) => true,
    // },
    favorites: {
        path: "/favorites",
        relativePath: "/favorites",
        component: FavoritesPage,
        auth: (user: UserEntity | undefined) => true,
    },
    terms_and_conditions: {
        path: "/legal/terms-and-conditions",
        relativePath: "/legal/terms-and-conditions",
        component: TermsAndConditionsPage,
        auth: (user: UserEntity | undefined) => true,
    },
    privacy_policy: {
        path: "/legal/privacy-policy",
        relativePath: "/legal/privacy-policy",
        component: PrivacyPolicyPage,
        auth: (user: UserEntity | undefined) => true,
    },
    our_recommendations_for_you: {
        path: "/our-recommendations-for-you/:id",
        relativePath: "/our-recommendations-for-you",
        component: RecommendedByUserPage,
        auth: (user: UserEntity | undefined) => true,
    },
    error404: {
        path: "/page-not-found",
        relativePath: "/page-not-found",
        component: Error404Page,
        auth: (user: UserEntity | undefined) => true,
    },
}

export const allRoutes = [...Object.values(routesES), ...Object.values(routesEN)];

const routesD = () => {
    const lan = di.get<LocalizationProvider>(LocalizationProviderName).Actions.localization;
    return lan.code == "en" ? routesEN : routesES;
}

export const routes = routesD;

export default routes;