import di from "../../../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const GetRecommendedByUserPropertiesApiImpl = async (linkId: string): Promise<PropertyEntity[]> => {
    try{
        const relativeUrl = "/linkedPropertiesShare/getByLinkedId/" + linkId;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        if(!response) return [];
        return response.map((item: any) => PropertyDto.fromJSON(item));
    }catch(e){
        return [];
    }
}

export default GetRecommendedByUserPropertiesApiImpl;