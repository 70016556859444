import di from '../../../dependencyInjection/DependencyInjection';
import { MultimediaEntityType } from '../../../domain/entities/MultimediaEntity';
import PropertyEntity from '../../../domain/entities/PropertyEntity';
import TypeOfBusinessEntity from '../../../domain/entities/TypeOfBusinessEntity';
import LocalizationProvider, { LocalizationProviderName } from '../../../domain/providers/localization/LocalizationProvider';
import PreferencesProvider, { PreferencesProviderName } from '../../../domain/providers/preferences/PreferencesProvider';
import LocationDto from './LocationDto';
import MultimediaDto from './MultimediaDto';
import PersonDto from './PersonDto';
import TagDto from './TagDto';
import TypeOfBusinessDto from './TypeOfBusinessDto';
import TypeOfPropertyDto from './TypeOfPropertyDto';

const fromJSON = (json: any): PropertyEntity => {
    const multimedias = json['multimedias'] ? (json['multimedias'] as any[]).map(multimedia => MultimediaDto.fromJSON(multimedia)) : [];
    const property = {
        id: json['id'],
        createdAt: json['created_at'] ? new Date(json['created_at']) : undefined,
        updatedAt: json['updated_at'] ? new Date(json['updated_at']) : undefined,
        name: json['name'],
        multimedias: multimedias,
        previewImage: multimedias.find(multimedia => multimedia.type == MultimediaEntityType.image)?.url,
        tag: json['tag_property'] ? TagDto.fromJSON(json['tag_property']) : undefined,
        description: json['description'],
        privateDescription: json['private_description'],
        restrooms: json['restrooms'],
        rooms: json['rooms'] ?? 0,
        garage: json['garage'] ?? 0,
        area: json['area'] ? parseFloat(json['area']) : undefined,
        privateArea: json['private_area'] ? parseFloat(json['private_area']) : undefined,
        buildedArea: json['builded_area'] ? parseFloat(json['builded_area']) : undefined,
        typeKitchen: json['type_kitchen'] != null ? di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n(json['type_kitchen']) : undefined,
        buildYear: json['build_year'] ? parseInt(json['build_year']) : undefined,
        stratum: json['stratum'],
        floor: json['floor'],
        floors: json['floors'],
        parkings: json['parkings'],
        newProperty: json['new'] === 1,
        outstanding: json['outstanding'] === 1,
        location: json['location'] ? LocationDto.fromJSON(json['location']) : undefined,
        priceAdmin: json['price_admin'],
        typeProperty: json['type_property'] ? TypeOfPropertyDto.fromJSON(json['type_property']) : undefined,
        businesses: json['businesses'] ? (json['businesses'] as any[]).map(business => {
            return {
                price: business['price'],
                typeOfBusiness: TypeOfBusinessDto.fromJSON(business['type_business']),
            }
        }).sort((a, b) => b.price - a.price) : [],
        contact: json['short_contact'] ? PersonDto.fromJSON(json['short_contact']) : undefined,
        liked: false,
        seen: false,
        vis: json['vis'] == 1,
        likedCount: json['likes'],
        seenCount: json['visualizations'],
    };

    const liked = di.get<PreferencesProvider>(PreferencesProviderName).Actions.thisPropertyLikesMe(property);
    property.liked = liked;
    return property;
}

export default {
    fromJSON
};
