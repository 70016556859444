import { injectable } from "inversify";
import PropertyRepository from "../../repositories/PropertyRepository";
import PropertyEntity from "../../entities/PropertyEntity";

interface props {
    propertyRepository: PropertyRepository;
}

@injectable()
class GetRecommendedByUserPropertiesUseCase {
    _propertyRepository: PropertyRepository;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
    }

    public call = async (linkId: string): Promise<PropertyEntity[]> => {
        return await this._propertyRepository.getRecommendedByUserProperties(linkId);
    }
}

export default GetRecommendedByUserPropertiesUseCase;

export const GetRecommendedByUserPropertiesUseCaseName = "GetRecommendedByUserPropertiesUseCase";